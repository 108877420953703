












































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Global } from '@/store';

@Component({})
export default class ConfirmDialog extends Vue {
  @Global.State('lang') lang;

  @Prop() curves;
  @Prop() updateSelctedCurves;
  @Prop() curves_map;

  selected_curves = [];
  selected_curves_objects = [];
  checkbox_color = '#812E81';
  dialog = true;

  mounted(){
    this.curves.sort((c1, c2) => c1.name.localeCompare(c2.name));
  }

  closeModal() {
    this.$emit('close');
  }

  handleSelectChanged(){
    this.selected_curves_objects = this.selected_curves.map((curve_id) => this.curves_map.get(curve_id));
  }

  confirmAction(){
    this.updateSelctedCurves(this.selected_curves_objects);
    this.closeModal();
  }
}
